.task-category-select{
  width: 100%;
  height: 50px;
  display: flex;
  align-items: stretch;
  gap: 8px;
  margin-top: 10px;
}
.task-category-select button{
  font-family: "TD Mars";
font-size: 14px;
display: flex;
align-items: center;
flex: 1;
justify-content: center;
border-radius: 50px;
}
.task-category-select button.active{
  background-color: #DA4B66;
}
.task-list{
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}
.task-card{
  border-radius: 50px;
  padding: 18px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.task-card p{
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
}
.task-card-reward-info{
  font-family: "TD Mars";
  display: flex;
  align-items: center;
  gap: 5px;
}
.task-card-reward-info span{
  width: max-content;
}
.task-card-reward-info img{
  width: 24px;
  height: 24px;
}