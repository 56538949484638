.user-wallet{
    display: flex;
    justify-content: center;
    gap: 6px;
    align-items: center;
    margin: 10px auto 0;
}
.balance-list{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
    margin-top: 24px;
}
.balance-list li{
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 10px;
}
.balance-info{
    display: flex;
    align-items: center;
    gap: 6px;
}
.balance-info h5{
  font-family: "TD Mars";
  font-size: 12px;

}
.balance-info img,.balance-info svg{
    width: 24px;
    height: 24px;
}
.balance-actions{
    display: flex;
    gap: 14px;
    align-items: center;
}
.balance-actions h5{
    font-family: "TD Mars";
    font-size: 12px;
}
.transaction{
    padding: 14px 10px 14px 14px !important;
}
.transaction .balance-info{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.transaction .balance-info span{
    font-size: 12px;
    font-family: "Montserrat", sans-serif;
}
