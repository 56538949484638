.friends-reward-info{
  width: 100%;
  display: flex;
  align-items: stretch;
  gap: 6px;
  height: 80px;
  margin: 10px 0 24px;
}
.friends-reward-info-block{
  flex: 1;
  padding: 14px;
  display: flex;
  border:1.5px solid #DA4B66;
  border-radius: 14px;
  gap: 6px;
}
.friends-reward-info-block img{
  width: 32px;
  height: 32px;
}
.reward-info-block-text h5{
  font-family: "TD Mars";
  
}
.reward-info-block-text p{
  font-size: 10px;
}
.friends-list-top{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.friends-list-top>p{
  font-weight: 700;
  font-size: 14px;
}
.from-friends{
  display: flex;
  gap: 6px;
  align-items: center;
}
.from-friends img{
  width: 32px;
  height: 32px;
}
.friends-list{
  margin-bottom: 60px;
}
.friend-card{
  border-radius: 50px;
  padding: 16px 10px;
  display: flex;
  align-items: center;
  margin-top: 10px;
  justify-content: space-between;
}
.friend-card-info{
  display: flex;
  align-items: center;
  gap: 6px;
}
.friend-card-photo{
  border-radius: 50%;
  width: 24px;
  height: 24px;
  border: 1.5px solid white;
}
.friend-card-info-text{
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.friend-card-info-text p{
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
}
.friend-card-info-stats{
  height: 10px;
  gap: 4px;
  align-items: center;
  display: flex;
  font-size: 10px;
}
.friend-card-info-stats .coin{
  width: 13px;
  height: 13px;
}
.friend-card-revenue{
  display: flex;
  gap: 4px;
  align-items: center;
}
.friend-card-revenue img{
  width: 25px;
  height: 25px;
}
.friend-card-revenue h5{
  font-family: "TD Mars";
font-size: 10px;
}
.invite-friend-buttons{
  position: fixed;
  left: 0;
  bottom: 100px;
  padding: 0 16px;
  height: 50px;
  display: flex;
  align-items: stretch;
  gap: 8px;
  width: 100%;
}
.invite-friend-buttons button{
  background-color: #DA4B66;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 50px;
}
.invite-btn{
  flex: 1;
  font-family: "TD Mars";
font-size: 14px;
}