@import url(https://db.onlinewebfonts.com/c/8154c98ce5303f03687d16a65b279206?family=TD+Mars);
@import url(https://db.onlinewebfonts.com/c/73e177b8f64802141601085dd1c1fc66?family=Montserrat+Alternates);
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
*,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li,
button,
input
hr a {
  background: none;
  border: none;
  outline: none;
  box-sizing: border-box;
  border: none;
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
  color: white;
  user-select: none;
  font-family: "Montserrat Alternates";
  -webkit-tap-highlight-color: transparent;
}
#root{
  height: inherit;
  width: 100%;
  overflow-x: hidden;
}

img{
  pointer-events: none;
}

html,
body {
    overflow: hidden;
    background: url(./assets/images/bg.jpg) no-repeat center/cover;
    color: #fff;
  height: 100%;
}
h1{
  font-family: "TD Mars";
  font-size: 27px;
  text-align: center;
}
.page-wrapper{
  transition: backdrop-filter .2s;
  width: 100%;
  overflow-x: hidden;
  height: inherit;
    padding: 10px 16px 100px;
}
.pink-opacity {
  background-color: #da4b6538;
  backdrop-filter: blur(10px);
}
.blured-page{
  backdrop-filter: blur(24px) brightness(0.7);
}
.text-light{
  text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.5),
  0px 0px 10px rgba(255, 255, 255, 0.5);
}
.Toastify__toast {
  background-color: #DA4B66 !important;
}
/* NAVIGATION */

nav{
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 0px 15.5px 26px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 6px;
}
.nav-button{
  cursor: pointer;
  transition: .2s;
  border-radius: 10px;
  border:1.5px solid white;
  flex: 1;
  flex-direction: column;
  gap: 3px;
  padding: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav-button img{
  height: 24px;
}
.nav-button.current{
  box-shadow: 0px 0px 20px rgba(255, 255, 255, 1),
  0px 0px 20px rgba(255, 255, 255, 1);
}
.chest-rewards{
  display: flex;
  padding: 10px;
  gap: 10px;
  position: absolute;
  top: 30%;
  width: max-content;
  border-radius: 50px;
}
.chest-rewards.active{
  box-shadow: 0px 0px 10px white,0px 0px 10px white,0px 0px 10px white;
}
.chest-rewards > *{
  height: 24px;
}
.daily-rewards-list{
  position: absolute;
  left: 16px;
  bottom: 100px;
  display: flex;
  flex-direction: column-reverse;
  gap: 13px;
}
.daily-rewards-list li{
  font-family: "TD Mars";
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.hero{
  position: absolute;
  bottom: 120px;
  right: 20%;
  height: 226px;
}
.hero-greeting{
  font-size: 10px;
  position: absolute;
  top: 25%;
  left: 16px;
  width: 150px;
  padding: 5px;
  /* transform: translateX(-50%) ; */
  border-radius: 10px;
  background-color: white;
  color: black;
  z-index: 999;
}
.hero-greeting::after{
  content: '';
  position: absolute;
  top: 50%;
  right: 2px;
  width: 10px;
  transform: translateX(100%) translateY(-50%) rotate(-90deg);
  height: 10px;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='13' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.50001 12.5C6.50001 12.5 -2.49999 2.375 1.50001 0.6875H11.5C5 2 6.50001 12.5 6.50001 12.5Z' fill='white'/%3E%3C/svg%3E%0A");
}
.daily-rewards-list li.current{
  color: #FFCE40;
  padding: 5px;
  box-shadow: 0px 0px 10px white,0px 0px 10px white,0px 0px 10px white;
  border-radius: 50px;
  width: fit-content;
  backdrop-filter: blur(10px);
}
.daily-rewards-list li img{
  width: 24px;
  height: 24px;
}
