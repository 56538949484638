.main-page{
  background: url("../../assets/images/main-bg.jpg") no-repeat center/cover;

}
.main-top-bar{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.main-top-bar-profile {
  display: flex;
  align-items: center;
  gap: 6px;
}
.main-top-bar-profile img{
  border-radius: 50%;
  width: 36px;
  height: 36px;
  border:1.5px solid white;
}
.main-top-bar-profile p{
  font-weight: 700;
  font-size: 14px;
}
.main-top-bar-activity p{
  text-shadow: 0px 0px 10px rgba(255, 255, 255, 1);
}
.main-top-bar-activity span{
  font-size: 10px;
}
.main-top-bar-balance{
  display: flex;
  align-items: center;
  font-family: "TD Mars";
  gap: 6px;
}
.main-top-bar-balance img{
  width: 32px;
  height: 32px;
}
.under-construction{
  position: absolute;
  top: 50%;
  width: 100%;
  left: 50%;
  transform: translate(-50%,-50%);
}