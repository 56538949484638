.entry-page {
  background: url("../../assets/images/entry-bg.jpg") no-repeat center/cover;
  display: flex;
  flex-direction: column;
  padding: 35px 0;
  height: 100%;
}
.entry-loading-screen{
  position: absolute;
  z-index: 4;
  inset: 0;
  background-color: black;
}
.entry-loading-news {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: end;
  gap: 10px;
  overflow: hidden;
  padding: 15px;
}
.entry-loading-news-block{
    padding: 0px 10px 24px;
    position: relative;
    border-radius: 14px;
}
.entry-loading-news-block svg{
    position: absolute;
    top: 7px;
    right: 6px;
}
.entry-loading-news-block p{
    font-size: 14px;
}

.entry-loading-news-quarter{
    background-color: #DA4B66;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translate(-20px,-15px);
}

.entry-loading-news-quarter h4{
    font-size: 12px;
  font-family: "TD Mars";

}
.entry-loading-news-quarter p{
    font-size: 10px;
}

.entry-loading-progress {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 22px;
  padding: 15px;

}
.entry-loading-progress h3 {
  font-family: "TD Mars";
  font-size: 16px;
}
.entry-loading-progress-bar-container{
    width: 100%;
    border-radius: 50px;
    height: 17px;
}
.entry-loading-progress-bar{
    height: 100%;
    border-radius: inherit;
    background: rgb(11,193,162);
    box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.5),
    0px 0px 8px rgba(255, 255, 255, 0.5);
background: linear-gradient(270deg, rgba(11,193,162,1) 0%, rgba(218,75,102,1) 100%);
}