.store-page {
    background: url("../../assets/images/store-bg.png") no-repeat center/cover;
}
.store-page h1{
    font-size: 20px;
  font-family: "TD Mars";
  position: absolute;
  left: 0;
  bottom: 100px;
  text-align: center;
  width: 100%;
}